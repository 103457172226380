import React from "react";
import styles from "./AddtionalCommentsComponent.module.css"; // Importing the same CSS Module

const ActionableAdvice = ({ data: _data }) => {
	const data = _data.content;
	const imporvementPlan = data.ImprovementPlan;
	const recommendations = data.Recommendation;
	return (
		<div className={styles.AddtionalCommentsComponent}>
			<div className={styles.container}>
				<h1 className={styles.title}>Actionable Advice and Guidance</h1>

				<section className={styles.mostFrequentMistake}>
					<h2 className={styles.sectionTitle}>Actionable Advice</h2>
					<div className={styles.card}>
						{data &&
							Object.keys(data["ActionableAdvice"]).map((key, index) => {
								return (
									<p className={styles.mistakeDescription} key={index}>
										<strong>{key}:</strong> {data["ActionableAdvice"][key]}
									</p>
								);
							})}
					</div>
				</section>
				<h1 className={styles.title}>Improvement Plan</h1>
				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>
						Appointment Setting and Closing Statements
					</h2>
					<ul className={styles.mistakeList}>
						{imporvementPlan &&
							Object.keys(
								imporvementPlan["AppointmentSettingAndClosingStatements"]
							).map((key, index) => {
								return (
									<li key={index} className={styles.mistakeItem}>
										<strong>{key}:</strong>{" "}
										{
											imporvementPlan["AppointmentSettingAndClosingStatements"][
												key
											]
										}
									</li>
								);
							})}
					</ul>
				</section>

				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>Exact Script Adherence</h2>
					<ul className={styles.mistakeList}>
						{imporvementPlan &&
							Object.keys(imporvementPlan["ExactScriptAdherence"]).map(
								(key, index) => {
									return (
										<li key={index} className={styles.mistakeItem}>
											<strong>{key}:</strong>{" "}
											{imporvementPlan["ExactScriptAdherence"][key]}
										</li>
									);
								}
							)}
					</ul>
				</section>

				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>
						Reordered Phrases And Flexibility
					</h2>
					<ul className={styles.mistakeList}>
						{imporvementPlan &&
							Object.keys(
								imporvementPlan["ReorderedPhrasesAndFlexibility"]
							).map((key, index) => {
								return (
									<li key={index} className={styles.mistakeItem}>
										<strong>{key}:</strong>{" "}
										{imporvementPlan["ReorderedPhrasesAndFlexibility"][key]}
									</li>
								);
							})}
					</ul>
				</section>

				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>Synonym Usage</h2>
					<ul className={styles.mistakeList}>
						{imporvementPlan &&
							Object.keys(imporvementPlan["SynonymUsage"]).map((key, index) => {
								return (
									<li key={index} className={styles.mistakeItem}>
										<strong>{key}:</strong>{" "}
										{imporvementPlan["SynonymUsage"][key]}
									</li>
								);
							})}
					</ul>
				</section>
				{/* <section className={styles.mistakeList}>
					<h2 className={styles.sectionTitle}>Guidance</h2>
					<ul className={styles.mistakeList}>
						<li className={styles.mistakeItem}>
							<strong>Emphasis on Precision:</strong> <br />
							{data["Guidance"]["Emphasis on Precision"]}
						</li>

						<li className={styles.mistakeItem}>
							<strong>Output Consistency:</strong> <br />
							{data["Guidance"]["Output Consistency"]}
						</li>

						<li className={styles.mistakeItem}>
							<strong>Single Focus for Improvement:</strong> <br />
							{data["Guidance"]["Single Focus for Improvement"]}
						</li>
					</ul>
				</section> */}

				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>Recommendation</h2>
					<ul className={styles.mistakeList}>
						{recommendations &&
							Object.keys(recommendations).map((key, index) => {
								return (
									<li key={index} className={styles.mistakeItem}>
										<strong>{key}:</strong> {recommendations[key]}
									</li>
								);
							})}
					</ul>
				</section>
			</div>
		</div>
	);
};

export default ActionableAdvice;
