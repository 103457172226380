import React, { useState } from "react";
import { Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { removeToken } from "../utilities/TokenSetting";
import { useNavigate } from "react-router-dom";

const AccountAvatar = ({ user }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		// Add your logout logic here
		removeToken();
		localStorage.removeItem("houzoo_user_data");
		handleClose();
		navigate("/login");
	};

	const handleProfile = () => {
		// Add your profile navigation logic here
		navigate("/profile");
		handleClose();
	};

	return (
		<div>
			<IconButton onClick={handleClick}>
				<Avatar sx={{ width: 40, height: 40, bgcolor: deepOrange[500] }}>
					{user?.first_name[0]}
				</Avatar>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					style: {
						minWidth: 200, // Adjust this value to make the menu wider
						borderRadius: "8px",
					},
				}}
			>
				<MenuItem onClick={handleProfile}>Profile</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</div>
	);
};

export default AccountAvatar;
