import styles from "./AddtionalCommentsComponent.module.css";

const AddtionalCommentsComponent = ({ title, mistakesData }) => {
	return (
		<div className={styles.AddtionalCommentsComponent}>
			<div className={styles.container}>
				<h1 className={styles.title}>{title}</h1>

				<section className={styles.mostFrequentMistake}>
					<h2 className={styles.sectionTitle}>Most Frequent Mistake</h2>
					<div className={styles.card}>
						<p className={styles.mistakeDescription}>
							<strong>Agent Dialogue:</strong>{" "}
							{mistakesData?.MostFrequentMistake.AgentDialogue}
						</p>
						<p className={styles.mistakeDescription}>
							<strong>Script Sentence:</strong>{" "}
							{mistakesData?.MostFrequentMistake.ScriptSentence}
						</p>
						<p className={styles.mistakeDescription}>
							<strong>Explanation:</strong>{" "}
							{mistakesData?.MostFrequentMistake.Explanation}
						</p>
						<p className={styles.mistakeDescription}>
							<strong>Actionable Advice:</strong>{" "}
							{mistakesData?.MostFrequentMistake.ActionableAdvice}
						</p>
					</div>
				</section>


				<section className={styles.additionalMistakes}>
					<h2 className={styles.sectionTitle}>Additional Mistakes</h2>
					<ul className={styles.mistakeList}>
						{mistakesData?.AdditionalMistakes.map((mistake, index) => (
							<li key={index} className={styles.mistakeItem}>
								<strong>Mistake #{mistake?.MistakeNumber}:</strong>{" "}
								<em>Agent Dialogue:</em> {mistake?.AgentDialogue} <br />
								<em>Script Sentence:</em> {mistake?.ScriptSentence}
							</li>
						))}
					</ul>
				</section>

				<section className={styles.deviationsTable}>
					<h2 className={styles.sectionTitle}>Deviations Table</h2>
					<div className={styles.tableWrapper}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th className={styles.tableHeader}>Agent Dialogue</th>
									<th className={styles.tableHeader}>Script Sentence</th>
									<th className={styles.tableHeader}>Description</th>
								</tr>
							</thead>
							<tbody>
								{mistakesData?.DeviationsTable.map((deviation, index) => (
									<tr key={index} className={styles.tableRow}>
										<td className={styles.tableCell}>
											{deviation?.AgentDialogue}
										</td>
										<td className={styles.tableCell}>
											{deviation?.ScriptSentence}
										</td>
										<td className={styles.tableCell}>
											{deviation?.Description}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</div>
	);
};

export default AddtionalCommentsComponent;
