import React, { useState, useEffect } from "react";
import CustomTextField from "../../@components/CustomTextField";
import { Container, Box, Stack, Card, Typography, Alert } from "@mui/material";
import CustomButton from "../../@components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import Colors from "../../@colors/Colors";
import { usePost } from "../../hooks/HttpsRequestHooks";
import baseUrl from "../../@api/BaseUrls";
import { setToken, getToken } from "../../utilities/TokenSetting";
import { useAuth } from "../../contexts/UserLoginContext";
import { useLocation } from "react-router-dom";
const Login = () => {
	const { data, error, loading, postData } = usePost();
	const { user, login, logout } = useAuth();
	const [errorMessageHandler, setErrorMessageHandler] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const location_id = queryParams.get("location_id");
	const session_key = queryParams.get("sessionkey");

	const navigate = useNavigate();
	const getEmail = (email) => {
		setEmail(email);
	};
	const getPassword = (password) => {
		setPassword(password);
	};
	useEffect(() => {
		if (error) {
			setErrorMessageHandler(error);
		}
	}, [error, setErrorMessageHandler]);

	useEffect(() => {
		setErrorMessageHandler("");
	}, [setErrorMessageHandler, email, password]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const _data = { email, password };

		try {
			const res = postData(`${baseUrl}/login`, _data, {
				"Content-Type": "application/json",
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		// If token is set, navigate to dashboard
		if (data) {
			login(data);
			navigate("/locations");
		}
	}, [data]);

	useEffect(() => {
		// If token is in local storage, navigate to dashboard
		if (getToken()) {
			navigate("/locations");
		}
	}, []);

	useEffect(() => {
		if (location_id && session_key) {
			const _data = {
				location_id,
				session_key,
			};
			try {
				postData(`${baseUrl}/login`, _data, {
					"Content-Type": "application/json",
				});
			} catch (err) {
				console.log(err);
			}
		}
	}, [location_id, session_key]);

	const style = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};
	return (
		<Container
			style={{
				...style,
				height: "100vh",
			}}
		>
			<Box
				style={{
					...style,
					mr: 2,
					flex: 3,
				}}
			>
				<img height="350rem" src="./images/logo-rep-2.svg" alt="..." />
			</Box>
			<Box ml={2} flex={2}>
				<Card
					sx={{ pt: 5, pb: 5, height: "60vh", borderRadius: 3 }}
					elevation={3}
				>
					<Box style={style} sx={{ w: "100%" }}>
						<form
							onSubmit={handleSubmit}
							style={{ borderColor: "red", width: "75%" }}
						>
							<Box style={style} mb={3}>
								<h3
									style={{
										fontWeight: "bold",
										color: Colors.primary,
									}}
								>
									Sign into your account
								</h3>
							</Box>
							{errorMessageHandler && (
								<Alert style={{ marginBottom: "1rem" }} severity="error">
									Email or password is incorrect!
								</Alert>
							)}
							<Stack spacing={2} mb={4} width="100%">
								<CustomTextField placeholder="Email" onValueChange={getEmail} />
								<Stack spacing={1}>
									<CustomTextField
										placeholder="Password"
										onValueChange={getPassword}
										isPassword
									/>
									<Link to="/reset-password" style={{ textDecoration: "none" }}>
										<Typography
											variant="body1"
											fontSize="0.9rem"
											style={{ color: Colors.primary }}
										>
											Forgot Password
										</Typography>
									</Link>
								</Stack>
							</Stack>

							<CustomButton
								text="Login"
								type="submit"
								style={{ width: "100%" }}
								loading={loading}
							/>
						</form>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};

export default Login;
