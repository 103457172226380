import { useEffect, useState } from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	Paper,
	TextField,
} from "@mui/material";
import baseUrl from "../../@api/BaseUrls";
import DateRangeComponent from "../../@components/DateRangePicker";
import { useAuth } from "../../contexts/UserLoginContext";
import useSWR from "swr";
import { fetcher } from "../../hooks/HttpsRequestHooks";
import ComboChart from "../../@components/ComboChart";
import AddiontalComments from "./AddionalComments";

const CallTranscriptionStatisticsPage = () => {
	const { user } = useAuth();
	const [userData, setUserData] = useState(user);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedSubAccount, setSelectedSubAccount] = useState(null);
	const [selectedSubAccountSid, setSelectedSubAccountSid] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isSelectedId, setIsSelectedId] = useState(false);

	const {
		data: accountsData,
		isLoading: accountsLoading,
		error: accountsError,
	} = useSWR(`${baseUrl}/twilio-accounts/`, fetcher);

	useEffect(() => {
		if (accountsData && accountsData.data.length > 0) {
			setSelectedOption(accountsData.data[0]); // Set the default value to the first option
		}
	}, [accountsData]);

	const {
		data: subAccountsData,
		isLoading: subAccountsLoading,
		error: subAccountsError,
	} = useSWR(
		selectedOption
			? `${baseUrl}/twilio-accounts/?sid=${selectedOption.sid}`
			: null,
		fetcher
	);

	const is_subaccount = (() => {
		if (userData?.is_admin === 1 && selectedSubAccountSid) {
			return `${baseUrl}/twilio-subaccount-users/?sid=${selectedSubAccountSid}`;
		} else if (userData?.is_admin === 0) {
			return `${baseUrl}/twilio-subaccount-users/?sid=${userData?.account_sid}`;
		} else {
			return null; // No valid URL, SWR will not fetch
		}
	})();

	const {
		data: users,
		error: usersError,
		isLoading: isLoadingUsers,
	} = useSWR(is_subaccount, fetcher);

	// URL construction for fetching transcription statistics
	const { data, error, isLoading } = useSWR(
		selectedUser
			? `${baseUrl}/transcription-statistics/?id=${selectedUser}&start_date=${selectedDate?.startDate.toLocaleDateString()}&end_date=${selectedDate?.endDate.toLocaleDateString()}`
			: null,
		fetcher
	);

	// Set the date callback
	const setDateCallback = (date) => {
		setSelectedDate(date);
	};

	useEffect(() => {
		const user_json = localStorage.getItem("houzoo_user_data");
		if (user_json) {
			const user_data = JSON.parse(user_json);
			setUserData(user_data);
		}
	}, []);

	useEffect(() => {
		if (selectedOption?.id) {
			setIsSelectedId(true);
		} else {
			setIsSelectedId(false);
		}
	}, [selectedOption?.id]);

	return (
		<Box sx={{ mt: 3 }}>
			{/* Container for Autocomplete components */}
			<Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
				<Box sx={{ mb: 3, display: "flex", gap: 2, pl: 6 }}>
					{user?.is_admin === 1 && (
						<Autocomplete
							disablePortal
							id="accounts"
							options={(accountsData && accountsData?.data) || []}
							noOptionsText={
								accountsLoading ? (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<CircularProgress size={24} />
									</div>
								) : accountsError ? (
									"Error loading accounts"
								) : (
									"No options"
								)
							}
							sx={{ width: 300, display: "none" }}
							renderInput={(params) => (
								<TextField {...params} size="small" label="Accounts" />
							)}
							getOptionLabel={(option) => option.friendly_name || ""}
							onChange={(event, newValue) => {
								setSelectedOption(newValue);
								setSelectedSubAccount(null);
								setSelectedSubAccountSid(null);
								setSelectedUser(null);
								setSelectedDate(null); // Clear date when account changes
							}}
							value={selectedOption}
						/>
					)}
					{user?.is_admin === 1 && (
						<Autocomplete
							disablePortal
							id="sub-accounts"
							options={(subAccountsData && subAccountsData?.data) || []}
							noOptionsText={
								subAccountsLoading ? (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<CircularProgress size={24} />
									</div>
								) : subAccountsError ? (
									"Error loading sub-accounts"
								) : (
									"No subaccounts"
								)
							}
							sx={{ width: 300 }}
							renderInput={(params) => (
								<TextField {...params} size="small" label="Sub-accounts" />
							)}
							getOptionLabel={(option) => option.friendly_name || ""}
							onChange={(event, newValue) => {
								setSelectedSubAccount(newValue);
								setSelectedSubAccountSid(newValue?.sid);
								// Clear user and date states when sub-account changes
								setSelectedUser(null);
								setSelectedDate(null);
							}}
							value={selectedSubAccount || null}
						/>
					)}
					<Autocomplete
						disablePortal
						id="users"
						options={(users && users?.data) || []}
						noOptionsText={
							isLoadingUsers ? (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<CircularProgress size={24} />
								</div>
							) : usersError ? (
								"Error loading users"
							) : (
								"No users found"
							)
						}
						sx={{ width: 300 }}
						renderInput={(params) => (
							<TextField {...params} size="small" label="Users" />
						)}
						getOptionLabel={(option) => option.phone_number || ""}
						onChange={(event, newValue) => {
							setSelectedUser(newValue?.phone_number || null);
							// Clear date state when user changes
							setSelectedDate(null);
						}}
						value={selectedUser ? { phone_number: selectedUser } : null}
					/>
				</Box>
				<DateRangeComponent setDateCallback={setDateCallback} />
			</Box>

			<Box sx={{ mt: 4, width: "98%", ml: "1%" }}>
				<Paper elevation={3} sx={{ p: 2 }}>
					<ComboChart data={data} loading={isLoading} />
				</Paper>
			</Box>
			<Box sx={{ mt: 4, width: "98%", ml: "1%" }}>
				<AddiontalComments id={selectedUser} />
			</Box>
		</Box>
	);
};

export default CallTranscriptionStatisticsPage;
