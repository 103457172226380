import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	getToken,
	getTokenRefresh,
	setAccessToken,
} from "../utilities/TokenSetting";
import baseUrl from "../@api/BaseUrls";
import { useSWRConfig } from "swr";

const fetcher = async (url) =>
	axios
		.get(url, {
			headers: {
				Authorization: "Bearer " + (await getToken()),
			},
		})
		.then((res) => res.data);

const createResource = async (path, data) => {
	// Function to get the authentication token

	const authToken = `Bearer ${await getToken()}`;

	try {
		const response = await axios.post(`${baseUrl}${path}`, data, {
			headers: {
				Authorization: authToken,
				"Content-Type": "application/json",
			},
		});

		return response.data;
	} catch (error) {
		// Handle or throw the error as needed
		throw {
			message: error.response ? error.response.data.message : error.message,
			status: error.response ? error.response.status : 500,
		};
	}
};

const handleTokenExpiration = async (error, setError) => {
	if (error.response && error.response.status === 401) {
		try {
			const newToken = await getToken(); // Renew the access token
			const newHeader = {
				Authorization: "Bearer " + newToken,
			};
			const response = await axios.get(`${baseUrl}//refresh-token`, {
				headers: newHeader,
			});
			setAccessToken(response.data.token.access_token);
		} catch (error) {
			setError(error); // Set error if token renewal fails
		}
	} else {
		setError(error); // Set error for other types of errors
	}
};

const useFetch = (url) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const header = {
					Authorization: "Bearer " + (await getToken()),
				};

				const response = await axios.get(url, {
					headers: header,
				});
				setData(response.data);
			} catch (error) {
				await handleTokenExpiration(error, setError);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [url]);

	return { data, error, loading };
};

const usePost = () => {
	const [dataWithHeader, setDataWithHeader] = useState(null);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const postData = async (url, post_data, header = {}) => {
		setLoading(true);
		try {
			header["Authorization"] = "Bearer " + (await getToken());
			const response = await axios.post(url, post_data, {
				headers: header,
			});
			setData(response.data);
			setDataWithHeader(response);
			setError(null);
		} catch (error_) {
			setError(error_);
			setData(null);
			setDataWithHeader(null);
			// await handleTokenExpiration(error, setError);
		} finally {
			setLoading(false);
		}
	};

	return { dataWithHeader, data, error, loading, postData };
};

const useDelete = () => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const deleteData = async (url, header = {}) => {
		setLoading(true);

		try {
			const token = await getToken();
			const response = await axios.delete(url, {
				headers: {
					...header,
					Authorization: `Bearer ${token}`,
				},
			});
			setData(response.data);
			setError(null);
		} catch (error_) {
			setData(null);
			setError(error_);
			// await handleTokenExpiration(error, setError);
		} finally {
			setLoading(false);
		}
	};

	return { data, error, loading, deleteData };
};

const useConditionalFetch = () => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState("");

	const fetchData = async (url) => {
		try {
			setLoading(true);
			const header = {
				Authorization: "Bearer " + (await getToken()),
			};
			const response = await axios.get(url, { headers: header });
			setData(response.data);
		} catch (error) {
			await handleTokenExpiration(error, setError);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (url) {
			fetchData(url);
		}
	}, [url]);

	return { data, error, loading, fetchUrl: setUrl };
};

export {
	useFetch,
	usePost,
	useDelete,
	useConditionalFetch,
	fetcher,
	createResource,
};
