import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import SidebarMenu from "../@components/SideBarMenu";
import Navbar from "../@components/SideBarMenu";
import { useFetch } from "../hooks/HttpsRequestHooks";
import baseUrl from "../@api/BaseUrls";
import { useCrmUser } from "../contexts/UserLoginContext";
import AccountAvatar from "../@components/UserProfiloAvatar";
import Colors from "../@colors/Colors";
import { useAuth } from "../contexts/UserLoginContext";
import { ToastContainer } from "react-toastify";

const MainLayout = () => {
	const { user } = useAuth();
	const { data } = useFetch(`${baseUrl}/current-user`);
	// Current user for CRM GHL
	const { userData } = useCrmUser();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/locations");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (data) {
			userData(data);
		}
	});
	return (
		<Box>
			<Navbar />

			<Box
				component="main"
				sx={{
					mt: "70px",
				}}
				// Criticle area for styling. MAIN LAYOUT POSISION
			>
				<Outlet />
			</Box>
			<ToastContainer />
		</Box>
	);
};

export default MainLayout;
