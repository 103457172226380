import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Material UI Components
import {
	Autocomplete,
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	IconButton,
	Modal,
	Paper,
	Skeleton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
	TextField,
} from "@mui/material";

// Custom Components
import AddLocation from "../../@components/LocationAddingForm";
import AlertDialog from "../../@components/alertcomponents/AlertDialog";

// Hooks and Utilities
import baseUrl from "../../@api/BaseUrls";
import { usePost, fetcher } from "../../hooks/HttpsRequestHooks";
import { useLocation } from "react-router-dom";
import useSWRInfinite from "swr/infinite";
import AccountTable from "../../@components/LocationsTable";

const getKey = (pageNo, PageSize = 10, previousPageData) => {
	// If there is no data from the previous page, we've reached the end
	if (previousPageData && !previousPageData.locations.length) return null;

	// Otherwise, return the URL for the next page
	return `${baseUrl}/locations/?pageNo=${pageNo + 1}&pageSize=${PageSize}`;
};

const columns = [
	"No.",
	"",
	"Account Name",
	"Email",
	"Twilio Connectivity",
	"AI Scoring",
	"Location ID",
	"Actions",
];

const loadingSkeleton = () => {
	const cellSkeleton = (
		<TableCell>
			<Typography variant="h3">
				<Skeleton />
			</Typography>
		</TableCell>
	);
	return Array.from({ length: 3 }).map((item, index) => (
		<TableRow key={index}>
			{cellSkeleton}
			<TableCell>
				<Skeleton variant="circular">
					<Avatar />
				</Skeleton>
			</TableCell>
			{Array.from({ length: 6 }).map((_, index) => (
				<React.Fragment key={index}>{cellSkeleton}</React.Fragment>
			))}
		</TableRow>
	));
};

export default function Locations() {
	const [openServiceAlert, setOpenServiceAlert] = useState(false);
	const [shouldProceed, setShouldProceed] = useState(false);
	const [locationArg, setLocationArg] = useState(null);

	const [tempData, setTempData] = useState([]);
	const [code, setCode] = useState(""); // Code For getting auth token
	const location = useLocation();
	const {
		// data: postResponse,
		// error: postError,
		loading: postLoading,
		postData,
	} = usePost();
	const [accountSid, setAccountSid] = useState("");
	const [authToken, setAuthToken] = useState("");
	const [locationId, setLocationId] = useState("");

	const [errors, setErrors] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNo, setPageNo] = useState(0);

	const [selectedOption, setSelectedOption] = useState(null);

	const [checked, setChecked] = useState(null);
	const [activeService, setActiveService] = useState(null);
	const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
	const {
		data,
		error,
		size: pageSize,
		setSize: setPageSize,
		isLoading: isLoadingLocations,
	} = useSWRInfinite(
		(index) => (autoCompleteOpen ? `${baseUrl}/get-locations/${index}` : null),
		fetcher
	);

	const handleAutoCompleteOpen = () => {
		// autoCompleteData(`${baseUrl}/get-locations/${optionsPage}`);
		setAutoCompleteOpen(true);
	};

	const handleAutoCompleteClose = () => {
		setAutoCompleteOpen(false);
	};
	const [mutated, setMutated] = useState(false);
	const {
		data: accountsData,
		error: swrError,
		isLoading,
		mutate,
	} = useSWRInfinite(() => getKey(pageNo, rowsPerPage), fetcher);

	const handleChangePage = (event, newPage) => {
		setPageNo(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPageNo(0);
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const newErrors = {};

		if (!accountSid) {
			newErrors.accountSid = "Account SID is required";
		}
		if (!authToken) {
			newErrors.authToken = "Auth Token is required";
		}

		if (Object.keys(newErrors).length === 0) {
			const data = {
				sid: accountSid,
				token: authToken,
				locationId: locationId,
			};
			try {
				await postData(`${baseUrl}/add-twilio/`, data);
				handleClose(); // Close the modal
				setAccountSid(""); // Clear form fields
				setAuthToken("");
				setLocationId("");
				setErrors({});
				toast.success("Account added successfully", {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			} catch (error) {
				console.error(error);
			}
		} else {
			setErrors(newErrors);
		}
	};

	const handleSwitchChange = async (event, locid) => {
		const status = event.target.checked ? "1-" : "0-";
		if (!event.target.checked) {
			setOpenServiceAlert(true);
			if (!shouldProceed) {
				const location_id = `${status}${locid}`;
				setLocationArg(location_id);
				return;
			}
		}

		setChecked(locid);
		setActiveService(event.target.checked);
		const location_id = `${status}${locid}`;
		setLocationArg(location_id);
		try {
			await postData(`${baseUrl}/active-twilio/${location_id}`);
			toast.success("Transcription Service enabled", {
				position: "bottom-left",
				autoClose: 1500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			mutate();
			// setChecked(null);
		} catch (error) {
			console.error(error);
		}
	};

	const onSuccessCallback = async () => {
		try {
			await postData(`${baseUrl}/active-twilio/${locationArg}`);
			toast.success("Transcription Service disabled", {
				position: "bottom-left",
				autoClose: 1500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			mutate();
			setShouldProceed(false);
			setLocationArg(null);
			// setChecked(null);
		} catch (error) {
			console.error(error);
		}
	};

	const allLocations = data
		? data.flatMap((page) => page?.locations || [])
		: [];

	useEffect(() => {
		if (data) {
			if (data[0]?.message === 401) {
				return;
			}
			setTempData((prevTempData) => {
				const existingIds = new Set(prevTempData.map((item) => item.id));
				const newLocations = allLocations.filter(
					(item) => !existingIds.has(item.id)
				);
				return [...prevTempData, ...newLocations];
			});
		}
	}, [data]);

	useEffect(() => {
		// Getting The code parameter from the url to get tokens from GHL CRM
		if (location?.search) {
			const param_value = location.search.split("=")[1];
			setCode(param_value);
			try {
				postData(`${baseUrl}/auth`, { code });
			} catch (error) {
				console.log(error);
			}
		}
		// eslint-disable-next-line
	}, [location, code]);

	const handleAutoCompleteScroll = (event) => {
		const { target } = event;
		if (
			!isLoadingLocations &&
			target.scrollHeight - target.scrollTop === target.clientHeight
		) {
			setPageSize((prevSize) => prevSize + 1); // Load next page
		}
	};

	function updateTable(flag) {
		setMutated(true);
		if (flag) {
			mutate();
			setTimeout(() => {
				setMutated(false);
			}, 1000);
		}
	}
	return (
		<Box
			style={{
				paddingLeft: "10%",
				paddingRight: "10%",
			}}
		>
			<AlertDialog
				open={openServiceAlert}
				onDisagree={() => {
					setOpenServiceAlert(false);
					setShouldProceed(false);
				}}
				onAgree={() => {
					setOpenServiceAlert(false);
					setShouldProceed(true);
					onSuccessCallback();
				}}
				title="Turn Off Transcription"
				description="Disabling the call transcription service will result in the deletion of the associated service from Twilio. Are you sure you want to proceed with this deletion?"
			/>
			<Custombackdrop postLoading={postLoading} mutated={mutated} />

			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "100%",

					gap: 2,
				}}
			>
				<Autocomplete
					open={autoCompleteOpen}
					onOpen={handleAutoCompleteOpen}
					onClose={handleAutoCompleteClose}
					disablePortal
					id="locations"
					options={tempData || []}
					noOptionsText={
						isLoadingLocations ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<CircularProgress size={24} />
							</div>
						) : error ? (
							"Error loading locations"
						) : (
							"No options"
						)
					}
					sx={{ width: 300 }}
					renderInput={(params) => (
						<TextField {...params} size="small" label={"Add Sub Account"} />
					)}
					getOptionLabel={(option) => option.name || ""}
					onChange={(event, newValue) => {
						setSelectedOption(newValue);
					}}
					value={selectedOption}
					ListboxProps={{
						onScroll: handleAutoCompleteScroll,
						style: { maxHeight: 200, overflow: "auto" },
					}}
					renderOption={(props, option) => (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)}
				/>

				{/* callback={updateTable} */}
				<AddLocation option={selectedOption} onSuccessCallback={updateTable} />
			</Box>
			{/* <Container sx={{ bgcolor: "" }}> */}
			<AccountTable
				columns={columns}
				accountsData={accountsData}
				isLoading={isLoading}
				loadingSkeleton={() => loadingSkeleton()} // Define a loading skeleton component if needed
				handleOpen={handleOpen}
				setLocationId={setLocationId}
				open={open}
				handleClose={handleClose}
				accountSid={accountSid}
				setAccountSid={setAccountSid}
				authToken={authToken}
				setAuthToken={setAuthToken}
				handleSubmit={handleSubmit}
				errors={errors}
				postLoading={postLoading}
				handleSwitchChange={handleSwitchChange}
				checked={checked}
				activeService={activeService}
				updateTable={updateTable}
				pageNo={pageNo}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			{/* </Container> */}
		</Box>
	);
}

function Custombackdrop({ postLoading, mutated }) {
	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: (theme) => theme.zIndex.drawer + 1,
				backgroundColor: "rgba(0, 0, 0, 0.5)",
			}}
			open={postLoading || mutated}
		>
			{/* <CircularProgress color="inherit" /> */}
			<div>
				<div className="vertical-loader">
					<div className="line line1"></div>
					<div className="line line2"></div>
					<div className="line line3"></div>
					<div className="line line4"></div>
					<div className="line line5"></div>
				</div>
				<Typography>Loading...</Typography>
			</div>
			<style>
				{`
                    .vertical-loader {
                        display: flex;
                        justify-content: space-between;
                        width: 80px; /* Adjusted to accommodate wider lines */
                        align-items: flex-end;
                    }

                    .line {
                        width: 8px; /* Doubled the width */
                        height: 40px;
                        background-color: #fff;
                        border-radius: 4px; /* Adjusted to match the increased width */
                        animation: vibrate 1.2s infinite;
                        transform-origin: bottom; /* Ensures vibration around the base */
                    }

                    .line1 {
                        animation-delay: 0s;
                    }

                    .line2 {
                        animation-delay: 0.2s;
                    }

                    .line3 {
                        animation-delay: 0.4s;
                    }

                    .line4 {
                        animation-delay: 0.6s;
                    }

                    .line5 {
                        animation-delay: 0.8s;
                    }

                    @keyframes vibrate {
                        0%, 100% {
                            transform: translateY(0);
                            opacity: 1;
                        }
                        50% {
                            transform: translateY(-10px);
                            opacity: 0.5;
                        }
                    }
                `}
			</style>
		</Backdrop>
	);
}
